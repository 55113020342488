import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import './MegaMenu.css'; // Import general CSS file for styling
import css from './MegaMenu2.module.css'; // Import CSS module for scoped styling

import { brands } from '../../../config/configBrands'; // Brands data
import { megaMenuItems } from '../../../config/configCategories'; // Menu items data

const MegaMenu = () => {
  const [activeCategory, setActiveCategory] = useState(null); // State to track active menu
  const [brandsVisible, setBrandsVisible] = useState(false); // State for showing the brands dropdown
  const [abrands, setBrands] = useState(brands);
  const timeoutRef = useRef(null); // Ref to store the timeout ID

  const isBrowser = typeof window !== 'undefined'; // Check if window is defined

  const isMobile = () => isBrowser && window.innerWidth <= 768; // Determine if it's mobile based on screen width

  const handleCategoryClick = menuItem => {
    if (menuItem.link) {
      // If the item has a link, redirect to that page
      window.location.href = menuItem.link;
    } else if (menuItem.title === 'Brands') {
      // Toggle the brands dropdown
      setBrandsVisible(!brandsVisible);
      setActiveCategory(null); // Close other dropdowns
    } else if (isMobile()) {
      // Toggle the menu items when clicked in mobile mode
      if (activeCategory === menuItem.title) {
        setActiveCategory(null); // Close the menu
        document.body.classList.remove('menuOpen'); // Remove scroll lock
      } else {
        setActiveCategory(menuItem.title); // Open the menu
        document.body.classList.add('menuOpen'); // Add scroll lock
        setBrandsVisible(false); // Close brands dropdown if another menu is clicked
      }
    }
  };

  const handleMouseEnter = menuItem => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (!isMobile()) {
      if (menuItem.link) {
        setActiveCategory(null); // Don't open a dropdown for link items like "Stores"
      } else if (menuItem.title === 'Brands') {
        setBrandsVisible(true); // Show brands dropdown on hover
        setActiveCategory(null); // Close other dropdowns
      } else {
        setActiveCategory(menuItem.title); // Open dropdown for other categories
        setBrandsVisible(false); // Close brands dropdown if another menu is hovered
      }
    }
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(() => {
      setActiveCategory(null); // Close mega menu
      setBrandsVisible(false); // Close brands dropdown
    }, 40); // Adjust the delay for smoother user experience
  };

  const handleMenuEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const filterBrands = event => {
    const value = event.target.value;

    const filteredBrands = brands.filter(brand =>
      brand.toLowerCase().includes(value.toLowerCase())
    );
    setBrands(filteredBrands);
  };

  const gotoBrand = brand => {
    if (typeof window !== 'undefined') {
      window.location.href = `/s?pub_brand=${brand}`;
    }
  };

  useEffect(() => {
    // Clean up the timeout when the component unmounts
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className={css.shopContainer}>
      {/* Shop Buttons */}
      <div className={css.shopButtons}>
        {megaMenuItems.map((menuItem, index) => (
          <div
            key={index}
            className={classNames(css.shopButton, {
              [css.active]: activeCategory === menuItem.title,
            })}
            onMouseEnter={() => handleMouseEnter(menuItem)} // Hover for desktop
            onMouseLeave={handleMouseLeave} // Adjusted handler
            onClick={() => handleCategoryClick(menuItem)} // Click to toggle submenu on mobile or redirect
          >
            <span>{menuItem.title}</span>
          </div>
        ))}
      </div>

      {/* Brands Dropdown */}
      {brandsVisible && (
        <div
          className={css.brandsDropdown}
          onMouseEnter={handleMenuEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input type="text" placeholder="Search brands" onChange={filterBrands} />
          <ul className={css.brandsList}>
            {abrands.map((brand, index) => (
              <li key={index} className={css.brandItem} onClick={() => gotoBrand(brand)}>
                <a href={`/s?pub_brand=${brand}`}>{brand}</a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Mega Menu */}
      {activeCategory && !megaMenuItems.find(item => item.title === activeCategory)?.link && (
        <div
          className={classNames(css.megaMenu, { [css.visible]: activeCategory })}
          onMouseEnter={handleMenuEnter} // Keep menu open when hovering over content
          onMouseLeave={handleMouseLeave} // Adjusted handler
        >
          <div className={css.menuContent}>
            {/* Clothing Column */}
            <div className={classNames(css.menuColumn, css.menuColumnScroll)}>
              <h4>
                {megaMenuItems.find(item => item.title === activeCategory).categories[0].label}
              </h4>
              <ul className={css.menuList}>
                {megaMenuItems
                  .find(item => item.title === activeCategory)
                  .column1.map((item, index) => (
                    <li key={index}>
                      <a href={item.link}>{item.label}</a>
                    </li>
                  ))}
              </ul>
            </div>

            {/* Shoes Column */}
            <div className={css.menuColumn}>
              <h4>
                {megaMenuItems.find(item => item.title === activeCategory).categories[1].label}
              </h4>
              <ul className={css.menuList}>
                {megaMenuItems
                  .find(item => item.title === activeCategory)
                  .column2.map((item, index) => (
                    <li key={index}>
                      <a href={item.link}>{item.label}</a>
                    </li>
                  ))}
              </ul>
            </div>

            {/* Accessories Column */}
            <div className={css.menuColumn}>
              <h4>
                {megaMenuItems.find(item => item.title === activeCategory).categories[2].label}
              </h4>
              <ul className={css.menuList}>
                {megaMenuItems
                  .find(item => item.title === activeCategory)
                  .column3.map((item, index) => (
                    <li key={index}>
                      <a href={item.link}>{item.label}</a>
                    </li>
                  ))}
              </ul>
            </div>

            {/* Featured Brands Column */}
            <div className={css.featuredBrandsColumn}>
              <h4>Featured Brands</h4>
              <ul className={css.brandList}>
                {megaMenuItems
                  .find(item => item.title === activeCategory)
                  .featuredBrands.map((brand, index) => (
                    <li key={index}>
                      <a href={brand.link}>{brand.name}</a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { MegaMenu };
